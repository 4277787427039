import { useParams } from 'react-router-dom';
import { useState,useEffect } from 'react';
import { SpecialZoomLevel,Viewer,Worker } from '@react-pdf-viewer/core';
import { getFilePlugin } from '@react-pdf-viewer/get-file';
import Center from './Center';

import '@react-pdf-viewer/core/lib/styles/index.css';

const ViewerComponent = () => {
    const { id } = useParams();

    const [url, setUrl] = useState(null);

    useEffect(() => {
        fetch(`https://doc.starmoney.co.th/api/Invoice/${id}`).then(response => {
            if(response.status != 404){
                response.blob().then(value => setUrl(URL.createObjectURL(value)))
            } else {
                setUrl('NOT_FOUND');
            }
        });
    },[]);

    const getFilePluginInstance = getFilePlugin({
        fileNameGenerator: (file) => {

            const fileName = file.name.substring(file.name.lastIndexOf('/') + 1);
            return `${fileName}.pdf`;
        },
    });

    const { DownloadButton } = getFilePluginInstance;
    

    if(!url) return <Center text="กรุณารอสักครู่"/>

    if(url == 'NOT_FOUND') return <Center text="ไม่พบรายการที่ท่านร้องขอ"/>

    return <Worker workerUrl="https://unpkg.com/pdfjs-dist@3.0.279/build/pdf.worker.js">
        <div
            style={{
                marginLeft:'auto',
                marginRight:'auto',
                width: '100%',
                height: '95vh'
            }}
        >
           
            <DownloadButton />
        
            <Viewer fileUrl={url} defaultScale={SpecialZoomLevel.PageWidth} plugins={[getFilePluginInstance]}/>
        </div>
    </Worker>
}

export default ViewerComponent;