const Center = ({text}) => {
    return <div style={{
        display:'flex',
        justifyContent: 'center',
        alignItems: 'center',
        height: '100vh'
    }}
    >
        {text}
    </div>
}

export default Center;